/**
  * features/compose/components/favourite_tags
  */

.favourite-tags__icon {
  width: 15px;
  padding: 0 5px;
  text-align: center;
}

.favourite-tags__lock {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -7px;
  width: 14px;
  text-align: center;

  a {
    color: $primary-text-color;
  }
}

.favourite-tags__add-button-in-column {
  margin-bottom: 5px;
  background-color: $ui-highlight-color;

  &:focus,
  &:hover {
    background-color: lighten($ui-highlight-color, 5%);
  }
}

.favourite-tags__remove-button-in-column {
  margin-bottom: 5px;
  background-color: $error-value-color;

  &:focus,
  &:hover {
    background-color: lighten($error-value-color, 5%);
  }
}
